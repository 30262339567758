function stripHTML(html) {
  var tmp = document.createElement('DIV');
  tmp.innerHTML = html.replace('<br />', ' ');
  return tmp.textContent || tmp.innerText;
}
function urlParam(url, name) {
  var results = new RegExp('[?&]' + name + '=([^&#]*)').exec(url);
  return results !== null ? results[1] : 0;
}
(function ($) {
  $.fn.transitionIntoGallery = function () {
    main = this;
    main.click(function (e) {
      $('body').prepend(
        '<div class="blackLayer" style="position:absolute;top:0px;left:0px;z-index:5000;width:' +
          $(window).width() +
          'px;height:' +
          $('html').height() +
          'px;background-color:#333f48;display:none"></div>'
      );
      e.preventDefault();
      $.removeCookie('offsetTop', { path: '/' });
      $.removeCookie('offsetTop');
      $.removeCookie('articleReturnURL');
      $.removeCookie('galleryReferer');
      $.removeCookie('galleryCatTitle');
      linkLocation = $(this).attr('href');
      referer = window.location.href;
      viewportOffset = $('html').offset().top * -1;
      if (document.title.indexOf('|') != -1) {
        if (document.location.href.indexOf('/clanek/') == -1) {
          documentTitle = document.title.substring(0, document.title.indexOf('|') - 1);
        } else {
          documentTitle = 'článek';
        }
      } else {
        documentTitle = 'iSport.cz';
      }
      var date = new Date();
      date.setTime(date.getTime() + 5 * 60 * 1000);
      $.cookie('offsetTop', viewportOffset, { path: '/', expires: date });
      $.cookie('articleReturnURL', linkLocation);
      $.cookie('galleryReferer', referer, { path: '/' });
      $.cookie('galleryCatTitle', documentTitle, { path: '/' });
      $('.blackLayer').fadeIn(750, function () {
        window.location.href = linkLocation;
      });
    });
  };
})(jQuery);
(function ($) {
  $.fn.transitionPopup = function () {
    main = this;
    main.click(function (e) {
      e.preventDefault();
      offset_width = document.body.offsetWidth;
      offset_height = document.body.offsetHeight;
      $('body').prepend(
        '<div class="blackLayer" style="position:absolute;top:0px;left:0px;z-index:5000;width:' +
          $(window).width() +
          'px;height:' +
          $(document).height() +
          'px;background-color:#333f48;display:none;opacity:0.7;"></div>'
      );
      $('body').prepend(
        '<div class="imagePopup whiteContainer"><div class="closePopup">zavřít<span class="ico"></span></div><img src="' +
          this +
          '"/><div class="description">' +
          $(this).find('img').attr('alt') +
          '</div></div>'
      );
      $('<img/>')
        .attr('src', this)
        .load(function () {
          scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
          popup_width = $('.imagePopup img').width();
          popup_height = $('.imagePopup img').height();
          popup_css = {
            left: offset_width / 2 - popup_width / 2,
            top: scrollTop + window.innerHeight / 2 - popup_height / 2,
            width: popup_width,
            visibility: 'visible',
          };
          $('.blackLayer').fadeIn(300, function () {
            $('.imagePopup').css(popup_css).show();
            $('.imagePopup img').show();
          });
        });
      $('.blackLayer, .imagePopup').click(function () {
        $('.imagePopup').remove();
        $('.blackLayer, .imagePopup').fadeOut(300, function () {
          $('.blackLayer').remove();
        });
      });
    });
  };
})(jQuery);
$.fn.stickyfloat = function (options, lockBottom) {
  var sticky = this;
  var objClass = sticky.attr('class');
  var topPos = sticky.offset().top - parseFloat(sticky.css('marginTop').replace(/auto/, 0));
  var childrenHeightFull = 0;
  sticky
    .parent()
    .children()
    .each(function () {
      childrenHeightFull = parseFloat(childrenHeightFull) + parseFloat($(this).outerHeight());
    });
  var childrenHeight = childrenHeightFull - parseFloat(sticky.outerHeight());
  sticky.parent().css('height', sticky.closest('.container').height());
  $(window).scroll(function (evt) {
    var y = $(this).scrollTop();
    if (y > topPos) {
      if (
        y <
        topPos +
          parseFloat(sticky.closest('.container').outerHeight()) -
          childrenHeight -
          sticky.outerHeight()
      ) {
        sticky.addClass('fixed').removeAttr('style');
      } else {
        sticky.removeClass('fixed').css({ position: 'absolute', bottom: 0 });
      }
    } else {
      sticky.removeClass('fixed');
    }
  });
};
(function ($) {
  var defaults = {
      vertical: false,
      rtl: false,
      start: 1,
      offset: 1,
      size: null,
      scroll: 3,
      visible: null,
      animation: 'normal',
      easing: 'swing',
      auto: 0,
      wrap: null,
      arrayData: null,
      formatBy: itemHtml,
      initCallback: null,
      setupCallback: null,
      reloadCallback: null,
      itemLoadCallback: null,
      itemFirstInCallback: null,
      itemFirstOutCallback: null,
      itemLastInCallback: null,
      itemLastOutCallback: null,
      itemVisibleInCallback: null,
      itemVisibleOutCallback: null,
      animationStepCallback: null,
      buttonNextHTML: '<div></div>',
      buttonPrevHTML: '<div></div>',
      buttonNextEvent: 'click',
      buttonPrevEvent: 'click',
      buttonNextCallback: null,
      buttonPrevCallback: null,
      itemFallbackDimension: null,
    },
    windowLoaded = false;
  $(window).bind('load.carousel', function () {
    windowLoaded = true;
  });
  $.carousel = function (e, o) {
    this.options = $.extend({}, defaults, o || {});
    this.locked = false;
    this.autoStopped = false;
    this.container = null;
    this.clip = null;
    this.list = null;
    this.buttonNext = null;
    this.buttonPrev = null;
    this.buttonNextState = null;
    this.buttonPrevState = null;
    this.arrayData = this.options.arrayData;
    this.formatBy = this.options.formatBy;
    if (!o || o.rtl === undefined) {
      this.options.rtl = ($(e).attr('dir') || $('html').attr('dir') || '').toLowerCase() == 'rtl';
    }
    this.wh = !this.options.vertical ? 'width' : 'height';
    this.lt = !this.options.vertical ? (this.options.rtl ? 'right' : 'left') : 'top';
    var skin = '',
      split = e.className.split(' ');
    for (var i = 0; i < split.length; i += 1) {
      if (split[i].indexOf('carouselSkin') != -1) {
        $(e).removeClass(split[i]);
        skin = split[i];
        break;
      }
    }
    if (e.nodeName.toUpperCase() == 'DIV') {
      this.list = $(e);
      this.clip = this.list.parents('.carouselClip');
      this.container = this.list.parents('.carouselContainer');
    } else {
      this.container = $(e);
      this.list = this.container.find('div').eq(0);
      this.clip = this.container.find('.carouselClip');
    }
    if (this.clip.size() === 0) {
      this.clip = this.list.wrap('<div></div>').parent();
    }
    if (this.container.size() === 0) {
      this.container = this.clip.wrap('<div></div>').parent();
    }
    if (skin !== '' && this.container.parent()[0].className.indexOf('carouselSkin') == -1) {
      this.container.wrap('<div class=" ' + skin + '"></div>');
    }
    this.buttonPrev = $('.buttonPrev', this.container);
    if (this.buttonPrev.size() === 0 && this.options.buttonPrevHTML !== null) {
      this.buttonPrev = $(this.options.buttonPrevHTML).appendTo(this.container);
    }
    this.buttonPrev.addClass('buttonPrev');
    this.buttonNext = $('.buttonNext', this.container);
    if (this.buttonNext.size() === 0 && this.options.buttonNextHTML !== null) {
      this.buttonNext = $(this.options.buttonNextHTML).appendTo(this.container);
    }
    this.buttonNext.addClass('buttonNext');
    this.clip.addClass(this.className('carouselClip'));
    this.list.addClass(this.className('carouselList')).css(this.options.rtl ? 'right' : 'left', 0);
    this.container.addClass(this.className('carouselContainer'));
    if (!this.options.vertical && this.options.rtl) {
      this.container.addClass('carouselDirectionRtl').attr('dir', 'rtl');
    }
    var di =
      this.options.visible !== null ? Math.ceil(this.clipping() / this.options.visible) : null;
    var li = this.list.children('div');
    var self = this;
    if (li.size() > 0) {
      var wh = 0,
        j = this.options.offset;
      li.each(function () {
        self.format(this, j++);
        wh += self.dimension(this, di);
      });
      this.list.css(this.wh, wh + 100 + 'px');
      if (!o || o.size === undefined) {
        this.options.size = li.size();
      }
    }
    this.funcNext = function () {
      self.next();
    };
    this.funcPrev = function () {
      self.prev();
    };
    this.funcResize = function () {
      if (self.resizeTimer) {
        clearTimeout(self.resizeTimer);
      }
      self.resizeTimer = setTimeout(function () {
        self.reload();
      }, 100);
    };
    if (this.options.initCallback !== null) {
      this.options.initCallback(this, 'init');
    }
    if (!windowLoaded && $.browser.safari) {
      this.buttons(false, false);
      $(window).bind('load.carousel', function () {
        self.setup();
      });
    } else {
      this.setup();
    }
  };
  var $jc = $.carousel;
  $jc.fn = $jc.prototype = { carousel: '0.2.8' };
  $jc.fn.extend = $jc.extend = $.extend;
  $jc.fn.extend({
    setup: function () {
      this.first = null;
      this.last = null;
      this.prevFirst = null;
      this.prevLast = null;
      this.animating = false;
      this.timer = null;
      this.resizeTimer = null;
      this.tail = null;
      this.inTail = false;
      if (this.locked) {
        return;
      }
      this.list.css(this.lt, this.pos(this.options.offset) + 'px');
      var p = this.pos(this.options.start, true);
      this.prevFirst = this.prevLast = null;
      this.animate(p, false);
      $(window).unbind('resize.carousel', this.funcResize).bind('resize.carousel', this.funcResize);
      if (this.options.setupCallback !== null) {
        this.options.setupCallback(this);
      }
    },
    reset: function () {
      this.list.empty();
      this.list.css(this.lt, '0px');
      this.list.css(this.wh, '10px');
      if (this.options.initCallback !== null) {
        this.options.initCallback(this, 'reset');
      }
      this.setup();
    },
    reload: function () {
      if (this.tail !== null && this.inTail) {
        this.list.css(this.lt, $jc.intval(this.list.css(this.lt)) + this.tail);
      }
      this.tail = null;
      this.inTail = false;
      if (this.options.reloadCallback !== null) {
        this.options.reloadCallback(this);
      }
      if (this.options.visible !== null) {
        var self = this;
        var di = Math.ceil(this.clipping() / this.options.visible),
          wh = 0,
          lt = 0;
        this.list.children('div').each(function (i) {
          wh += self.dimension(this, di);
          if (i + 1 < self.first) {
            lt = wh;
          }
        });
        this.list.css(this.wh, wh + 'px');
        this.list.css(this.lt, -lt + 'px');
      }
      this.scroll(this.first, false);
    },
    lock: function () {
      this.locked = true;
      this.buttons();
    },
    unlock: function () {
      this.locked = false;
      this.buttons();
    },
    size: function (s) {
      if (s !== undefined) {
        this.options.size = s;
        if (!this.locked) {
          this.buttons();
        }
      }
      return this.options.size;
    },
    has: function (i, i2) {
      if (i2 === undefined || !i2) {
        i2 = i;
      }
      if (this.options.size !== null && i2 > this.options.size) {
        i2 = this.options.size;
      }
      for (var j = i; j <= i2; j += 1) {
        var e = this.get(j);
        if (!e.length || e.hasClass('itemPlaceholder')) {
          return false;
        }
      }
      return true;
    },
    get: function (i) {
      return $('>.carouselItem-' + i, this.list);
    },
    add: function (i, s) {
      var e = this.get(i),
        old = 0,
        n = $(s);
      if (e.length === 0) {
        var c,
          j = $jc.intval(i);
        e = this.create(i);
        while (true) {
          c = this.get((j -= 1));
          if (j <= 0 || c.length) {
            if (j <= 0) {
              this.list.prepend(e);
            } else {
              c.after(e);
            }
            break;
          }
        }
      } else {
        old = this.dimension(e);
      }
      if (n.get(0).nodeName.toUpperCase() == 'DIV') {
        e.replaceWith(n);
        e = n;
      } else {
        e.empty().append(s);
      }
      this.format(e.removeClass(this.className('itemPlaceholder')), i);
      var di =
        this.options.visible !== null ? Math.ceil(this.clipping() / this.options.visible) : null;
      var wh = this.dimension(e, di) - old;
      if (i > 0 && i < this.first) {
        this.list.css(this.lt, $jc.intval(this.list.css(this.lt)) - wh + 'px');
      }
      this.list.css(this.wh, $jc.intval(this.list.css(this.wh)) + wh + 'px');
      return e;
    },
    remove: function (i) {
      var e = this.get(i);
      if (!e.length || (i >= this.first && i <= this.last)) {
        return;
      }
      var d = this.dimension(e);
      if (i < this.first) {
        this.list.css(this.lt, $jc.intval(this.list.css(this.lt)) + d + 'px');
      }
      e.remove();
      this.list.css(this.wh, $jc.intval(this.list.css(this.wh)) - d + 'px');
    },
    next: function () {
      if (this.tail !== null && !this.inTail) {
        this.scrollTail(false);
      } else {
        this.scroll(
          (this.options.wrap == 'both' || this.options.wrap == 'last') &&
            this.options.size !== null &&
            this.last == this.options.size
            ? 1
            : this.first + this.options.scroll
        );
      }
    },
    prev: function () {
      if (this.tail !== null && this.inTail) {
        this.scrollTail(true);
      } else {
        this.scroll(
          (this.options.wrap == 'both' || this.options.wrap == 'first') &&
            this.options.size !== null &&
            this.first == 1
            ? this.options.size
            : this.first - this.options.scroll
        );
      }
    },
    scrollTail: function (b) {
      if (this.locked || this.animating || !this.tail) {
        return;
      }
      this.pauseAuto();
      var pos = $jc.intval(this.list.css(this.lt));
      pos = !b ? pos - this.tail : pos + this.tail;
      this.inTail = !b;
      this.prevFirst = this.first;
      this.prevLast = this.last;
      this.animate(pos);
    },
    scroll: function (i, a) {
      if (this.locked || this.animating) {
        return;
      }
      this.pauseAuto();
      this.animate(this.pos(i), a);
    },
    pos: function (i, fv) {
      var pos = $jc.intval(this.list.css(this.lt));
      if (this.locked || this.animating) {
        return pos;
      }
      if (this.options.wrap != 'circular') {
        i = i < 1 ? 1 : this.options.size && i > this.options.size ? this.options.size : i;
      }
      var back = this.first > i;
      var f = this.options.wrap != 'circular' && this.first <= 1 ? 1 : this.first;
      var c = back ? this.get(f) : this.get(this.last);
      var j = back ? f : f - 1;
      var e = null,
        l = 0,
        p = false,
        d = 0,
        g;
      while (back ? --j >= i : ++j < i) {
        e = this.get(j);
        p = !e.length;
        if (e.length === 0) {
          e = this.create(j).addClass(this.className('itemPlaceholder'));
          c[back ? 'before' : 'after'](e);
          if (
            this.first !== null &&
            this.options.wrap == 'circular' &&
            this.options.size !== null &&
            (j <= 0 || j > this.options.size)
          ) {
            g = this.get(this.index(j));
            if (g.length) {
              e = this.add(j, g.clone(true));
            }
          }
        }
        c = e;
        d = this.dimension(e);
        if (p) {
          l += d;
        }
        if (
          this.first !== null &&
          (this.options.wrap == 'circular' ||
            (j >= 1 && (this.options.size === null || j <= this.options.size)))
        ) {
          pos = back ? pos + d : pos - d;
        }
      }
      var clipping = this.clipping(),
        cache = [],
        visible = 0,
        v = 0;
      c = this.get(i - 1);
      j = i;
      while ((visible += 1)) {
        e = this.get(j);
        p = !e.length;
        if (e.length === 0) {
          e = this.create(j).addClass(this.className('itemPlaceholder'));
          if (c.length === 0) {
            this.list.prepend(e);
          } else {
            c[back ? 'before' : 'after'](e);
          }
          if (
            this.first !== null &&
            this.options.wrap == 'circular' &&
            this.options.size !== null &&
            (j <= 0 || j > this.options.size)
          ) {
            g = this.get(this.index(j));
            if (g.length) {
              e = this.add(j, g.clone(true));
            }
          }
        }
        c = e;
        d = this.dimension(e);
        if (d === 0) {
        }
        if (
          this.options.wrap != 'circular' &&
          this.options.size !== null &&
          j > this.options.size
        ) {
          cache.push(e);
        } else if (p) {
          l += d;
        }
        v += d;
        if (v >= clipping) {
          break;
        }
        j += 1;
      }
      for (var x = 0; x < cache.length; x += 1) {
        cache[x].remove();
      }
      if (l > 0) {
        this.list.css(this.wh, this.dimension(this.list) + l + 'px');
        if (back) {
          pos -= l;
          this.list.css(this.lt, $jc.intval(this.list.css(this.lt)) - l + 'px');
        }
      }
      var last = i + visible - 1;
      if (this.options.wrap != 'circular' && this.options.size && last > this.options.size) {
        last = this.options.size;
      }
      if (j > last) {
        visible = 0;
        j = last;
        v = 0;
        while ((visible += 1)) {
          e = this.get(j--);
          if (!e.length) {
            break;
          }
          v += this.dimension(e);
          if (v >= clipping) {
            break;
          }
        }
      }
      var first = last - visible + 1;
      if (this.options.wrap != 'circular' && first < 1) {
        first = 1;
      }
      if (this.inTail && back) {
        pos += this.tail;
        this.inTail = false;
      }
      this.tail = null;
      if (this.options.wrap != 'circular' && last == this.options.size && last - visible + 1 >= 1) {
        var m = $jc.intval(
          this.get(last).css(!this.options.vertical ? 'marginRight' : 'marginBottom')
        );
        if (v - m > clipping) {
          this.tail = v - clipping - m;
        }
      }
      if (fv && i === this.options.size && this.tail) {
        pos -= this.tail;
        this.inTail = true;
      }
      while (i-- > first) {
        pos += this.dimension(this.get(i));
      }
      this.prevFirst = this.first;
      this.prevLast = this.last;
      this.first = first;
      this.last = last;
      return pos;
    },
    animate: function (p, a) {
      if (this.locked || this.animating) {
        return;
      }
      this.animating = true;
      var self = this;
      var scrolled = function () {
        self.animating = false;
        if (p === 0) {
          self.list.css(self.lt, 0);
        }
        if (
          !self.autoStopped &&
          (self.options.wrap == 'circular' ||
            self.options.wrap == 'both' ||
            self.options.wrap == 'last' ||
            self.options.size === null ||
            self.last < self.options.size ||
            (self.last == self.options.size && self.tail !== null && !self.inTail))
        ) {
          self.startAuto();
        }
        self.buttons();
        self.notify('onAfterAnimation');
        if (self.options.wrap == 'circular' && self.options.size !== null) {
          for (var i = self.prevFirst; i <= self.prevLast; i += 1) {
            if (
              i !== null &&
              !(i >= self.first && i <= self.last) &&
              (i < 1 || i > self.options.size)
            ) {
              self.remove(i);
            }
          }
        }
      };
      this.notify('onBeforeAnimation');
      if (!this.options.animation || a === false) {
        this.list.css(this.lt, p + 'px');
        scrolled();
      } else {
        var o = !this.options.vertical
          ? this.options.rtl
            ? { right: p }
            : { left: p }
          : { top: p };
        var settings = {
          duration: this.options.animation,
          easing: this.options.easing,
          complete: scrolled,
        };
        if ($.isFunction(this.options.animationStepCallback)) {
          settings.step = this.options.animationStepCallback;
        }
        this.list.animate(o, settings);
      }
    },
    startAuto: function (s) {
      if (s !== undefined) {
        this.options.auto = s;
      }
      if (this.options.auto === 0) {
        return this.stopAuto();
      }
      if (this.timer !== null) {
        return;
      }
      this.autoStopped = false;
      var self = this;
      this.timer = window.setTimeout(function () {
        self.next();
      }, this.options.auto * 1000);
    },
    stopAuto: function () {
      this.pauseAuto();
      this.autoStopped = true;
    },
    pauseAuto: function () {
      if (this.timer === null) {
        return;
      }
      window.clearTimeout(this.timer);
      this.timer = null;
    },
    buttons: function (n, p) {
      if (n == null) {
        n =
          !this.locked &&
          this.options.size !== 0 &&
          ((this.options.wrap && this.options.wrap != 'first') ||
            this.options.size === null ||
            this.last < this.options.size);
        if (
          !this.locked &&
          (!this.options.wrap || this.options.wrap == 'first') &&
          this.options.size !== null &&
          this.last >= this.options.size
        ) {
          n = this.tail !== null && !this.inTail;
        }
      }
      if (p == null) {
        p =
          !this.locked &&
          this.options.size !== 0 &&
          ((this.options.wrap && this.options.wrap != 'last') || this.first > 1);
        if (
          !this.locked &&
          (!this.options.wrap || this.options.wrap == 'last') &&
          this.options.size !== null &&
          this.first == 1
        ) {
          p = this.tail !== null && this.inTail;
        }
      }
      var self = this;
      if (this.buttonNext.size() > 0) {
        this.buttonNext.unbind(this.options.buttonNextEvent + '.carousel', this.funcNext);
        if (n) {
          this.buttonNext.bind(this.options.buttonNextEvent + '.carousel', this.funcNext);
        }
        this.buttonNext[n ? 'removeClass' : 'addClass']('buttonNextDisabled').attr(
          'disabled',
          n ? false : true
        );
        if (
          this.options.buttonNextCallback !== null &&
          this.buttonNext.data('carouselstate') != n
        ) {
          this.buttonNext
            .each(function () {
              self.options.buttonNextCallback(self, this, n);
            })
            .data('carouselstate', n);
        }
      } else {
        if (this.options.buttonNextCallback !== null && this.buttonNextState != n) {
          this.options.buttonNextCallback(self, null, n);
        }
      }
      if (this.buttonPrev.size() > 0) {
        this.buttonPrev.unbind(this.options.buttonPrevEvent + '.carousel', this.funcPrev);
        if (p) {
          this.buttonPrev.bind(this.options.buttonPrevEvent + '.carousel', this.funcPrev);
        }
        this.buttonPrev[p ? 'removeClass' : 'addClass']('buttonPrevDisabled').attr(
          'disabled',
          p ? false : true
        );
        if (
          this.options.buttonPrevCallback !== null &&
          this.buttonPrev.data('carouselstate') != p
        ) {
          this.buttonPrev
            .each(function () {
              self.options.buttonPrevCallback(self, this, p);
            })
            .data('carouselstate', p);
        }
      } else {
        if (this.options.buttonPrevCallback !== null && this.buttonPrevState != p) {
          this.options.buttonPrevCallback(self, null, p);
        }
      }
      this.buttonNextState = n;
      this.buttonPrevState = p;
    },
    notify: function (evt) {
      var state = this.prevFirst === null ? 'init' : this.prevFirst < this.first ? 'next' : 'prev';
      this.callback('itemLoadCallback', evt, state);
      if (this.prevFirst !== this.first) {
        this.callback('itemFirstInCallback', evt, state, this.first);
        this.callback('itemFirstOutCallback', evt, state, this.prevFirst);
      }
      if (this.prevLast !== this.last) {
        this.callback('itemLastInCallback', evt, state, this.last);
        this.callback('itemLastOutCallback', evt, state, this.prevLast);
      }
      this.callback(
        'itemVisibleInCallback',
        evt,
        state,
        this.first,
        this.last,
        this.prevFirst,
        this.prevLast
      );
      this.callback(
        'itemVisibleOutCallback',
        evt,
        state,
        this.prevFirst,
        this.prevLast,
        this.first,
        this.last
      );
    },
    callback: function (cb, evt, state, i1, i2, i3, i4) {
      if (
        this.options[cb] == null ||
        (typeof this.options[cb] != 'object' && evt != 'onAfterAnimation')
      ) {
        return;
      }
      var callback = typeof this.options[cb] == 'object' ? this.options[cb][evt] : this.options[cb];
      if (!$.isFunction(callback)) {
        return;
      }
      var self = this;
      if (i1 === undefined) {
        callback(self, state, evt);
      } else if (i2 === undefined) {
        this.get(i1).each(function () {
          callback(self, this, i1, state, evt);
        });
      } else {
        var call = function (i) {
          self.get(i).each(function () {
            callback(self, this, i, state, evt);
          });
        };
        for (var i = i1; i <= i2; i += 1) {
          if (i !== null && !(i >= i3 && i <= i4)) {
            call(i);
          }
        }
      }
    },
    create: function (i) {
      return this.format('<div></div>', i);
    },
    format: function (e, i) {
      e = $(e);
      var split = e.get(0).className.split(' ');
      for (var j = 0; j < split.length; j += 1) {
        if (split[j].indexOf('carousel-') != -1) {
          e.removeClass(split[j]);
        }
      }
      e.addClass(this.className('carouselItem')).addClass(this.className('carouselItem-' + i));
      return e;
    },
    className: function (c) {
      return c;
    },
    dimension: function (e, d) {
      var el = $(e);
      if (d == null) {
        return !this.options.vertical
          ? el.outerWidth(true) || $jc.intval(this.options.itemFallbackDimension)
          : el.outerHeight(true) || $jc.intval(this.options.itemFallbackDimension);
      } else {
        var w = !this.options.vertical
          ? d - $jc.intval(el.css('marginLeft')) - $jc.intval(el.css('marginRight'))
          : d - $jc.intval(el.css('marginTop')) - $jc.intval(el.css('marginBottom'));
        $(el).css(this.wh, w + 'px');
        return this.dimension(el);
      }
    },
    clipping: function () {
      return !this.options.vertical
        ? this.clip[0].offsetWidth -
            $jc.intval(this.clip.css('borderLeftWidth')) -
            $jc.intval(this.clip.css('borderRightWidth'))
        : this.clip[0].offsetHeight -
            $jc.intval(this.clip.css('borderTopWidth')) -
            $jc.intval(this.clip.css('borderBottomWidth'));
    },
    index: function (i, s) {
      if (s == null) {
        s = this.options.size;
      }
      return Math.round(((i - 1) / s - Math.floor((i - 1) / s)) * s) + 1;
    },
  });
  $jc.extend({
    defaults: function (d) {
      return $.extend(defaults, d || {});
    },
    intval: function (v) {
      v = parseInt(v, 10);
      return isNaN(v) ? 0 : v;
    },
    windowLoaded: function () {
      windowLoaded = true;
    },
  });
  $.fn.carousel = function (o) {
    if (typeof o == 'string') {
      var instance = $(this).data('carousel'),
        args = Array.prototype.slice.call(arguments, 1);
      return instance[o].apply(instance, args);
    } else {
      return this.each(function () {
        var instance = $(this).data('carousel');
        if (instance) {
          if (o) {
            $.extend(instance.options, o);
          }
          instance.reload();
        } else {
          $(this).data('carousel', new $jc(this, o));
        }
      });
    }
  };
})(jQuery);
function dynamicCallback(carousel, state) {
  for (var i = carousel.first; i <= carousel.last; i += 1) {
    if (carousel.has(i)) {
      continue;
    }
    if (i > carousel.arrayData.length) {
      break;
    }
    formatBy = eval(carousel.formatBy);
    carousel.add(i, formatBy(carousel.arrayData[i - 1]));
  }
}
function itemHtml(item) {
  return '---';
}
function matchHtml(item) {
  if (item.typ == 'oth') {
    return (
      '<a href="' +
      item.url +
      '" class="' +
      item.typ +
      '"><span class="top"><span class="leagueName">' +
      item.league +
      '</span>' +
      item.live +
      '' +
      item.status +
      '<span class="round">' +
      item.xml_round +
      '</span></span></a>'
    );
  } else {
    var logo1 =
      item.logo1 === '' ? '' : '<img src="' + item.logo1 + '" alt="' + item.teamName1 + '" />';
    var logo2 =
      item.logo2 === '' ? '' : '<img src="' + item.logo2 + '" alt="' + item.teamName2 + '" />';
    return (
      '<a href="' +
      item.url +
      '" class="' +
      item.typ +
      '"><span class="top"><span class="leagueName">' +
      item.league +
      '</span>' +
      item.live +
      '<span class="logo">' +
      logo1 +
      '</span>' +
      item.status +
      '<span class="logo">' +
      logo2 +
      '</span><span class="teamNames">' +
      item.teamName1 +
      '<span> &#215; </span>' +
      item.teamName2 +
      '</span></span></a>'
    );
  }
}
function gallery(item) {
  return (
    '<a name="' +
    item.galid +
    '" href="' +
    item.galurl +
    '?foto=' +
    item.pos +
    '" class="galleryTransition"><img height="' +
    item.height +
    '" src="' +
    item.url +
    '" title="' +
    item.title +
    '" /></a>'
  );
}
(function ($, document, undefined) {
  var pluses = /\+/g;
  function raw(s) {
    return s;
  }
  function decoded(s) {
    return decodeURIComponent(s.replace(pluses, ' '));
  }
  $.cookie = function (key, value, options) {
    if (value !== undefined && !/Object/.test(Object.prototype.toString.call(value))) {
      options = $.extend({}, $.cookie.defaults, options);
      if (value === null) {
        options.expires = -1;
      }
      if (typeof options.expires === 'number') {
        var days = options.expires,
          t = (options.expires = new Date());
        t.setDate(t.getDate() + days);
      }
      value = String(value);
      return (document.cookie = [
        encodeURIComponent(key),
        '=',
        options.raw ? value : encodeURIComponent(value),
        options.expires ? '; expires=' + options.expires.toUTCString() : '',
        options.path ? '; path=' + options.path : '',
        options.domain ? '; domain=' + options.domain : '',
        options.secure ? '; secure' : '',
      ].join(''));
    }
    options = value || $.cookie.defaults || {};
    var decode = options.raw ? raw : decoded;
    var cookies = document.cookie.split('; ');
    for (var i = 0, parts; (parts = cookies[i] && cookies[i].split('=')); i += 1) {
      if (decode(parts.shift()) === key) {
        return decode(parts.join('='));
      }
    }
    return null;
  };
  $.cookie.defaults = {};
  $.removeCookie = function (key, options) {
    if ($.cookie(key, options) !== null) {
      $.cookie(key, null, options);
      return true;
    }
    return false;
  };
})(jQuery, document);
(function ($) {
  $.fn.hoverIntent = function (f, g) {
    var cfg = { sensitivity: 7, interval: 100, timeout: 0 };
    cfg = $.extend(cfg, g ? { over: f, out: g } : f);
    var cX, cY, pX, pY;
    var track = function (ev) {
      cX = ev.pageX;
      cY = ev.pageY;
    };
    var compare = function (ev, ob) {
      ob.hoverIntent_t = clearTimeout(ob.hoverIntent_t);
      if (Math.abs(pX - cX) + Math.abs(pY - cY) < cfg.sensitivity) {
        $(ob).unbind('mousemove', track);
        ob.hoverIntent_s = 1;
        return cfg.over.apply(ob, [ev]);
      } else {
        pX = cX;
        pY = cY;
        ob.hoverIntent_t = setTimeout(function () {
          compare(ev, ob);
        }, cfg.interval);
      }
    };
    var delay = function (ev, ob) {
      ob.hoverIntent_t = clearTimeout(ob.hoverIntent_t);
      ob.hoverIntent_s = 0;
      return cfg.out.apply(ob, [ev]);
    };
    var handleHover = function (e) {
      var ev = jQuery.extend({}, e);
      var ob = this;
      if (ob.hoverIntent_t) {
        ob.hoverIntent_t = clearTimeout(ob.hoverIntent_t);
      }
      if (e.type == 'mouseenter') {
        pX = ev.pageX;
        pY = ev.pageY;
        $(ob).bind('mousemove', track);
        if (ob.hoverIntent_s != 1) {
          ob.hoverIntent_t = setTimeout(function () {
            compare(ev, ob);
          }, cfg.interval);
        }
      } else {
        $(ob).unbind('mousemove', track);
        if (ob.hoverIntent_s == 1) {
          ob.hoverIntent_t = setTimeout(function () {
            delay(ev, ob);
          }, cfg.timeout);
        }
      }
    };
    return this.bind('mouseenter', handleHover).bind('mouseleave', handleHover);
  };
})(jQuery);
var errMsg = {
  name: 'Musíte vyplnit jméno.',
  empty: 'Povinné pole.',
  password: 'Musíte zadat heslo.',
  search: 'Zadejte hledaný výraz',
  email: 'Špatný formát emailu',
};
function checkForm(target) {
  var valid = true;
  $(target)
    .find(
      'input[type="text"], input[type="password"], input[type="search"], input[type="email"], input[type="url"]'
    )
    .each(function () {
      var input = $(this);
      if (input.hasClass('required') || input.hasClass('req')) {
        if (input.hasClass('name')) {
          if (input.val() === '') {
            input.addClass('err');
            input.attr('placeholder', errMsg.name);
            valid = false;
          }
        } else if (input.hasClass('email')) {
          var mail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
          if (mail.test(input.val()) === false) {
            input.addClass('err');
            input.attr('placeholder', errMsg.email);
            valid = false;
          }
        } else if (input.hasClass('search')) {
          if (input.val() === '') {
            input.addClass('err');
            input.attr('placeholder', errMsg.search);
            valid = false;
          }
        } else if (input.hasClass('pass')) {
          if (input.val() === '') {
            input.addClass('err');
            input.attr('placeholder', errMsg.password);
            valid = false;
          }
        } else {
          if (input.val() === '') {
            input.addClass('err');
            input.attr('placeholder', errMsg.empty);
            valid = false;
          }
        }
      }
    });
  return valid;
}
(function ($) {
  $.fn.styledSelect = function () {
    return this.each(function () {
      var $this = $(this),
        numberOfOptions = $(this).children('option').length;
      $this.addClass('s-hidden');
      $this.wrap('<div class="styledSelect"></div>');
      $this.after('<div class="select"></div>');
      var $styledSelect = $this.next('div.select');
      $styledSelect.text($this.children('option:selected').text());
      var $list = $('<ul />', { class: 'options' }).insertAfter($styledSelect);
      for (var i = 0; i < numberOfOptions; i += 1) {
        $('<li />', {
          text: $this.children('option').eq(i).text(),
          rel: $this.children('option').eq(i).val(),
        }).appendTo($list);
      }
      var $listItems = $list.children('li');
      $styledSelect.click(function (e) {
        e.stopPropagation();
        if (!$(this).hasClass('active')) {
          $('div.select.active').each(function () {
            $(this).removeClass('active').next('ul.options').hide();
          });
        }
        $(this).toggleClass('active').next('ul.options').toggle();
      });
      $listItems.click(function (e) {
        e.stopPropagation();
        $styledSelect.text($(this).text()).removeClass('active');
        $this.val($(this).attr('rel'));
        $list.hide();
      });
      $(document).click(function () {
        $styledSelect.removeClass('active');
        $list.hide();
      });
    });
  };
})(jQuery);
$(document).ready(function () {
  if ($.cookie('showNew') === null) {
    $.cookie('showNew', '1', { expires: 60, path: '/', domain: '.ahaonline.cz' });
  }
  var datum = new Date().getTime();
  $.cookie('visited', datum, { expires: 90, path: '/' });
  $('.versionSwitch a').click(function (e) {
    $.removeCookie('showNew', { expires: 60, path: '/', domain: '.ahaonline.cz' });
    return true;
  });
  if ($('#hpTopContent h1').innerHeight() < 77) {
    $('.box').css('marginBottom', '0');
  }
  $('a.galleryTransition').each(function () {
    $(this).transitionIntoGallery();
  });
  $('a.popupTransition').each(function () {
    $(this).transitionPopup();
  });
  if ($.cookie('offsetTop') !== null) {
    if (window.location.href == $.cookie('galleryReferer')) {
      $(document).scrollTop($.cookie('offsetTop'));
      $.removeCookie('offsetTop', { path: '/' });
      $.removeCookie('offsetTop');
      $.removeCookie('galleryReferer');
      $.removeCookie('galleryReferer', { path: '/' });
    }
  }
  $("a[href='#']").click(function (e) {
    e.preventDefault();
  });
  $('.clearMeOnClick').focus(function (e) {
    $(e.target).text('').removeClass('clearMeOnClick');
  });
  $('.numberOnly').bind('keydown', function (event) {
    if (
      event.keyCode == 46 ||
      event.keyCode == 8 ||
      event.keyCode == 9 ||
      event.keyCode == 27 ||
      event.keyCode == 13 ||
      (event.keyCode == 65 && event.ctrlKey === true) ||
      (event.keyCode == 86 && event.ctrlKey === true) ||
      (event.keyCode >= 35 && event.keyCode <= 39) ||
      event.shiftKey === true ||
      event.ctrlKey === true ||
      (event.keyCode >= 48 && event.keyCode <= 57)
    ) {
      return;
    } else {
      if (
        (event.keyCode < 48 || event.keyCode > 57) &&
        (event.keyCode < 96 || event.keyCode > 105)
      ) {
        event.preventDefault();
        $(event.target).addClass('err');
      }
    }
  });
  $('.numberOnly').bind('keyup paste', function (event) {
    $(event.target).removeClass('err');
    if (!/^[0-9\+]+$/.test($(this).val())) {
      $(this).val(
        $(this)
          .val()
          .replace(/[^0-9\+]/g, '')
      );
    }
  });
  $('.vertical a.facebook').hoverIntent({
    interval: 50,
    over: function (e) {
      $(this)
        .find('.fb-like-container')
        .animate({ width: 'toggle' }, 200, function () {
          if ($(this).find('.fb-like').length > 0) {
            $(e.target).parent().find('.loader').remove();
          } else {
            $(this).append(
              '<div class="fb-like" data-send="false" data-layout="button_count" data-href="' +
                $(this).data('url') +
                '" data-width="400" data-show-faces="false">'
            );
            FB.XFBML.parse(e.target, function () {
              $(e.target).parent().find('.loader').remove();
              $(e.target).parent().find('.fb-like').show();
            });
          }
        });
    },
    out: function () {
      $(this)
        .find('.fb-like-container')
        .animate({ width: 'toggle' }, 200, function () {});
    },
  });
  $('.horizontal a.facebook').hoverIntent({
    interval: 50,
    over: function (e) {
      $(this)
        .find('.fb-like-container')
        .slideDown(200, function () {
          if ($(this).find('.fb-like').length <= 0) {
            $(this).append(
              '<div class="fb-like" data-send="false" data-layout="button_count" data-href="' +
                $(this).data('url') +
                '" data-width="300" data-show-faces="false">'
            );
            FB.XFBML.parse(e.target, function () {
              $(e.target).parent().find('.loader').remove();
              $(e.target).parent().find('.fb-like').show();
            });
          } else {
            $(e.target).parent().find('.loader').remove();
          }
        });
    },
    out: function () {
      $(this)
        .find('.fb-like-container')
        .slideUp(200, function () {});
    },
  });
  $('a.twitter').click(function (e) {
    e.preventDefault();
    shareUrl = encodeURIComponent($(this).data('url'));
    window.open(
      'http://twitter.com/share?url=' + shareUrl,
      $(this).data('via'),
      'toolbar=0,status=0,width=600,height=400,left = 300,top = 200'
    );
    return false;
  });
  var now = parseFloat($('.boxTvprogram').attr('data-dec')),
    totalWidth = parseInt($('.boxTvprogram').attr('data-width'), 10);
  interval = 1 / 60;
  var tick = setInterval(function () {
    now = now + interval;
    $('.boxTvprogram .channel .time').each(function () {
      var width = totalWidth,
        from = parseFloat($(this).children('.from').attr('data-dec')),
        to = parseFloat($(this).children('.to').attr('data-dec')),
        progressbar = $(this).find('.progress');
      if (now >= from && now <= to) {
        width = Math.round((width * (now - from)) / (to - from));
      }
      progressbar.width(width);
    });
  }, 3600000 * interval);
  $('.boxTvprogram .channel .channelHeading .next').click(function (e) {
    var id = $(this).parents('.channel').attr('data-next');
    e.preventDefault();
    $(this).parents('.channel').hide();
    $('#' + id).show();
  });
  $('.boxTvprogram .channel .channelHeading .prev').click(function (e) {
    var id = $(this).parents('.channel').attr('data-prev');
    e.preventDefault();
    $(this).parents('.channel').hide();
    $('#' + id).show();
  });
  $('.mainSearch').submit(function () {
    $(this)
      .find('input[name=keyword]')
      .val(encodeURIComponent($(this).find('input[name=q]').val()));
    return true;
  });
  $('#main-content a[href^="#"]').click(function (e) {
    e.preventDefault();
    var scrollTarget = $(this).attr('href').substring(1);
    var destination = $('[id="' + scrollTarget + '"]').offset().top;
    $('html:not(:animated),body:not(:animated)').animate({ scrollTop: destination - 5 }, 800);
    return false;
  });
  $('.validate input, .validate textarea').focus(function () {
    $(this).removeClass('err');
  });
  if ($('.stickyContent').length !== 0) {
    $('.stickyContent').stickyfloat({ duration: 400 });
  }
  if (!('placeholder' in document.createElement('input'))) {
    $('input[placeholder], textarea[placeholder]').each(function () {
      var val = $(this).attr('placeholder');
      if (this.value === '') {
        this.value = val;
      }
      $(this)
        .focus(function () {
          if (this.value == val) {
            this.value = '';
          }
        })
        .blur(function () {
          if ($.trim(this.value) === '') {
            this.value = val;
          }
        });
    });
    $('form').submit(function () {
      $(this)
        .find('input[placeholder], textarea[placeholder]')
        .each(function () {
          if (this.value == $(this).attr('placeholder')) {
            this.value = '';
          }
        });
      if ($(this).hasClass('validate')) {
        if (checkForm($(this))) {
          return true;
        } else {
          event.preventDefault();
          return false;
        }
      }
    });
  }
});
var datumNow = new Date().getTime();
var datumNewUser = new Date().getTime();
if ($.cookie('newuser') == null) {
  $.cookie('newuser', '1', { expires: 60, path: '/' });
}
if ($.cookie('newuserDatum') == null) {
  $.cookie('newuserDatum', datumNewUser, { expires: 60, path: '/' });
}
if ($.cookie('visited') == null) {
  newUser = 1;
  $.cookie('newuser', '1', { expires: 60, path: '/' });
} else if (
  datumNow - $.cookie('newuserDatum') < 1000 * 60 * 60 * 24 &&
  $.cookie('newuser') == '1'
) {
  newUser = 1;
  $.cookie('newuser', '1', { expires: 60, path: '/' });
} else if (datumNow - $.cookie('visited') > 1000 * 60 * 60 * 24 * 60) {
  newUser = 1;
  $.cookie('newuser', '1', { expires: 60, path: '/' });
} else {
  newUser = 0;
  $.cookie('newuser', '0', { expires: 60, path: '/' });
}
(function (t) {
  'use strict';
  var i = (t.jCarousel = {});
  i.version = '0.3.0-beta.5';
  var s = /^([+\-]=)?(.+)$/;
  (i.parseTarget = function (t) {
    var i = !1,
      e = 'object' != typeof t ? s.exec(t) : null;
    return (
      e
        ? ((t = parseInt(e[2], 10) || 0), e[1] && ((i = !0), '-=' === e[1] && (t *= -1)))
        : 'object' != typeof t && (t = parseInt(t, 10) || 0),
      { target: t, relative: i }
    );
  }),
    (i.detectCarousel = function (t) {
      for (var i; t.size() > 0; ) {
        if (((i = t.filter('[data-jcarousel]')), i.size() > 0)) {
          return i;
        }
        if (((i = t.find('[data-jcarousel]')), i.size() > 0)) {
          return i;
        }
        t = t.parent();
      }
      return null;
    }),
    (i.base = function (s) {
      return {
        version: i.version,
        _options: {},
        _element: null,
        _carousel: null,
        _init: t.noop,
        _create: t.noop,
        _destroy: t.noop,
        _reload: t.noop,
        create: function () {
          return (
            this._element.attr('data-' + s.toLowerCase(), !0).data(s, this),
            !1 === this._trigger('create')
              ? this
              : (this._create(), this._trigger('createend'), this)
          );
        },
        destroy: function () {
          return !1 === this._trigger('destroy')
            ? this
            : (this._destroy(),
              this._trigger('destroyend'),
              this._element.removeData(s).removeAttr('data-' + s.toLowerCase()),
              this);
        },
        reload: function (t) {
          return !1 === this._trigger('reload')
            ? this
            : (t && this.options(t), this._reload(), this._trigger('reloadend'), this);
        },
        element: function () {
          return this._element;
        },
        options: function (i, s) {
          if (0 === arguments.length) {
            return t.extend({}, this._options);
          }
          if ('string' == typeof i) {
            if (s === void 0) {
              return this._options[i] === void 0 ? null : this._options[i];
            }
            this._options[i] = s;
          } else {
            this._options = t.extend({}, this._options, i);
          }
          return this;
        },
        carousel: function () {
          return (
            this._carousel ||
              ((this._carousel = i.detectCarousel(this.options('carousel') || this._element)),
              this._carousel || t.error('Could not detect carousel for plugin "' + s + '"')),
            this._carousel
          );
        },
        _trigger: function (i, e, r) {
          var n,
            o = !1;
          return (
            (r = [this].concat(r || [])),
            (e || this._element).each(function () {
              (n = t.Event((i + '.' + s).toLowerCase())),
                t(this).trigger(n, r),
                n.isDefaultPrevented() && (o = !0);
            }),
            !o
          );
        },
      };
    }),
    (i.plugin = function (s, e) {
      var r = (t[s] = function (i, s) {
        (this._element = t(i)), this.options(s), this._init(), this.create();
      });
      return (
        (r.fn = r.prototype = t.extend({}, i.base(s), e)),
        (t.fn[s] = function (i) {
          var e = Array.prototype.slice.call(arguments, 1),
            n = this;
          return (
            'string' == typeof i
              ? this.each(function () {
                  var r = t(this).data(s);
                  if (!r) {
                    return t.error(
                      `Cannot call methods on ${s} prior to initialization; attempted to call method "${i}"`
                    );
                  }
                  if (!t.isFunction(r[i]) || '_' === i.charAt(0)) {
                    return t.error(`No such method "${i}" for ${s} instance`);
                  }
                  var o = r[i].apply(r, e);
                  return o !== r && o !== void 0 ? ((n = o), !1) : void 0;
                })
              : this.each(function () {
                  var e = t(this).data(s);
                  e instanceof r ? e.reload(i) : new r(this, i);
                }),
            n
          );
        }),
        r
      );
    });
})(jQuery),
  (function (t, i) {
    'use strict';
    var s = function (t) {
      return parseFloat(t) || 0;
    };
    t.jCarousel.plugin('jcarousel', {
      animating: !1,
      tail: 0,
      inTail: !1,
      resizeTimer: null,
      lt: null,
      vertical: !1,
      rtl: !1,
      circular: !1,
      underflow: !1,
      _options: {
        list: function () {
          return this.element().children().eq(0);
        },
        items: function () {
          return this.list().children();
        },
        animation: 400,
        transitions: !1,
        wrap: null,
        vertical: null,
        rtl: null,
        center: !1,
      },
      _list: null,
      _items: null,
      _target: null,
      _first: null,
      _last: null,
      _visible: null,
      _fullyvisible: null,
      _init: function () {
        var t = this;
        return (
          (this.onWindowResize = function () {
            t.resizeTimer && clearTimeout(t.resizeTimer),
              (t.resizeTimer = setTimeout(function () {
                t.reload();
              }, 100));
          }),
          this
        );
      },
      _create: function () {
        this._reload(), t(i).on('resize.jcarousel', this.onWindowResize);
      },
      _destroy: function () {
        t(i).off('resize.jcarousel', this.onWindowResize);
      },
      _reload: function () {
        (this.vertical = this.options('vertical')),
          null == this.vertical && (this.vertical = this.list().height() > this.list().width()),
          (this.rtl = this.options('rtl')),
          null == this.rtl &&
            (this.rtl = (function (i) {
              if ('rtl' === ('' + i.attr('dir')).toLowerCase()) {
                return !0;
              }
              var s = !1;
              return (
                i.parents('[dir]').each(function () {
                  return /rtl/i.test(t(this).attr('dir')) ? ((s = !0), !1) : void 0;
                }),
                s
              );
            })(this._element)),
          (this.lt = this.vertical ? 'top' : 'left'),
          (this._items = null);
        var i = this._target && this.index(this._target) >= 0 ? this._target : this.closest();
        (this.circular = 'circular' === this.options('wrap')), (this.underflow = !1);
        var s = { left: 0, top: 0 };
        return (
          i.size() > 0 &&
            (this._prepare(i),
            this.list().find('[data-jcarousel-clone]').remove(),
            (this._items = null),
            (this.underflow = this._fullyvisible.size() >= this.items().size()),
            (this.circular = this.circular && !this.underflow),
            (s[this.lt] = this._position(i) + 'px')),
          this.move(s),
          this
        );
      },
      list: function () {
        if (null === this._list) {
          var i = this.options('list');
          this._list = t.isFunction(i) ? i.call(this) : this._element.find(i);
        }
        return this._list;
      },
      items: function () {
        if (null === this._items) {
          var i = this.options('items');
          this._items = (t.isFunction(i) ? i.call(this) : this.list().find(i)).not(
            '[data-jcarousel-clone]'
          );
        }
        return this._items;
      },
      index: function (t) {
        return this.items().index(t);
      },
      closest: function () {
        var i,
          e = this,
          r = this.list().position()[this.lt],
          n = t(),
          o = !1,
          a = this.vertical ? 'bottom' : this.rtl ? 'left' : 'right';
        return (
          this.rtl && !this.vertical && (r = -1 * (r + this.list().width() - this.clipping())),
          this.items().each(function () {
            if (((n = t(this)), o)) {
              return !1;
            }
            var l = e.dimension(n);
            if (((r += l), r >= 0)) {
              if (((i = l - s(n.css('margin-' + a))), !(0 >= Math.abs(r) - l + i / 2))) {
                return !1;
              }
              o = !0;
            }
          }),
          n
        );
      },
      target: function () {
        return this._target;
      },
      first: function () {
        return this._first;
      },
      last: function () {
        return this._last;
      },
      visible: function () {
        return this._visible;
      },
      fullyvisible: function () {
        return this._fullyvisible;
      },
      hasNext: function () {
        if (!1 === this._trigger('hasnext')) {
          return !0;
        }
        var t = this.options('wrap'),
          i = this.items().size() - 1;
        return i >= 0 &&
          ((t && 'first' !== t) || i > this.index(this._last) || (this.tail && !this.inTail))
          ? !0
          : !1;
      },
      hasPrev: function () {
        if (!1 === this._trigger('hasprev')) {
          return !0;
        }
        var t = this.options('wrap');
        return this.items().size() > 0 &&
          ((t && 'last' !== t) || this.index(this._first) > 0 || (this.tail && this.inTail))
          ? !0
          : !1;
      },
      clipping: function () {
        return this._element['inner' + (this.vertical ? 'Height' : 'Width')]();
      },
      dimension: function (t) {
        return t['outer' + (this.vertical ? 'Height' : 'Width')](!0);
      },
      scroll: function (i, e, r) {
        if (this.animating) {
          return this;
        }
        if (!1 === this._trigger('scroll', null, [i, e])) {
          return this;
        }
        t.isFunction(e) && ((r = e), (e = !0));
        var n = t.jCarousel.parseTarget(i);
        if (n.relative) {
          var o,
            a,
            l,
            h,
            u,
            c,
            f,
            d,
            _ = this.items().size() - 1,
            p = Math.abs(n.target),
            m = this.options('wrap');
          if (n.target > 0) {
            var v = this.index(this._last);
            if (v >= _ && this.tail) {
              this.inTail
                ? 'both' === m || 'last' === m
                  ? this._scroll(0, e, r)
                  : this._scroll(Math.min(this.index(this._target) + p, _), e, r)
                : this._scrollTail(e, r);
            } else if (
              ((o = this.index(this._target)),
              (this.underflow && o === _ && ('circular' === m || 'both' === m || 'last' === m)) ||
                (!this.underflow && v === _ && ('both' === m || 'last' === m)))
            ) {
              this._scroll(0, e, r);
            } else if (((l = o + p), this.circular && l > _)) {
              for (d = _, u = this.items().get(-1); l > d++; ) {
                (u = this.items().eq(0)),
                  (c = this._visible.index(u) >= 0),
                  c && u.after(u.clone(!0).attr('data-jcarousel-clone', !0)),
                  this.list().append(u),
                  c ||
                    ((f = {}),
                    (f[this.lt] = this.dimension(u) * (this.rtl ? -1 : 1)),
                    this.moveBy(f)),
                  (this._items = null);
              }
              this._scroll(u, e, r);
            } else {
              this._scroll(Math.min(l, _), e, r);
            }
          } else if (this.inTail) {
            this._scroll(Math.max(this.index(this._first) - p + 1, 0), e, r);
          } else if (
            ((a = this.index(this._first)),
            (o = this.index(this._target)),
            (h = this.underflow ? o : a),
            (l = h - p),
            0 >= h && ((this.underflow && 'circular' === m) || 'both' === m || 'first' === m))
          ) {
            this._scroll(_, e, r);
          } else if (this.circular && 0 > l) {
            for (d = l, u = this.items().get(0); 0 > d++; ) {
              (u = this.items().eq(-1)),
                (c = this._visible.index(u) >= 0),
                c && u.after(u.clone(!0).attr('data-jcarousel-clone', !0)),
                this.list().prepend(u),
                (this._items = null);
              var g = s(this.list().position()[this.lt]),
                y = this.dimension(u);
              this.rtl && !this.vertical ? (g += y) : (g -= y),
                (f = {}),
                (f[this.lt] = g + 'px'),
                this.move(f);
            }
            this._scroll(u, e, r);
          } else {
            this._scroll(Math.max(l, 0), e, r);
          }
        } else {
          this._scroll(n.target, e, r);
        }
        return this._trigger('scrollend'), this;
      },
      moveBy: function (t, i) {
        var e = this.list().position();
        return (
          t.left && (t.left = e.left + s(t.left) + 'px'),
          t.top && (t.top = e.top + s(t.top) + 'px'),
          this.move(t, i)
        );
      },
      move: function (i, s) {
        s = s || {};
        var e = this.options('transitions'),
          r = !!e,
          n = !!e.transforms,
          o = !!e.transforms3d,
          a = s.duration || 0,
          l = this.list();
        if (!r && a > 0) {
          return l.animate(i, s), void 0;
        }
        var h = l.css(['transitionDuration', 'transitionTimingFunction', 'transitionProperty']),
          u = function () {
            t(this).css(h), (s.complete || t.noop).call(this);
          },
          c = {
            transitionDuration: (a > 0 ? a / 1e3 : 0) + 's',
            transitionTimingFunction: e.easing || s.easing,
            transitionProperty:
              a > 0
                ? (function () {
                    return n || o ? 'all' : i.left ? 'left' : 'top';
                  })()
                : 'none',
            transform: 'none',
          };
        o
          ? (c.transform = 'translate3d(' + (i.left || 0) + ',' + (i.top || 0) + ',0)')
          : n
            ? (c.transform = 'translate(' + (i.left || 0) + ',' + (i.top || 0) + ')')
            : t.extend(c, i),
          a > 0 &&
            l.one(
              'transitionend webkitTransitionEnd oTransitionEnd otransitionend MSTransitionEnd',
              u
            ),
          l.css(c),
          0 >= a &&
            l.each(function () {
              u.call(this);
            });
      },
      _scroll: function (i, e, r) {
        if (this.animating) {
          return t.isFunction(r) && r.call(this, !1), this;
        }
        if (
          ('object' != typeof i ? (i = this.items().eq(i)) : i.jquery === void 0 && (i = t(i)),
          0 === i.size())
        ) {
          return t.isFunction(r) && r.call(this, !1), this;
        }
        (this.inTail = !1), this._prepare(i);
        var n = this._position(i),
          o = s(this.list().position()[this.lt]);
        if (n === o) {
          return t.isFunction(r) && r.call(this, !1), this;
        }
        var a = {};
        return (a[this.lt] = n + 'px'), this._animate(a, e, r), this;
      },
      _scrollTail: function (i, s) {
        if (this.animating || !this.tail) {
          return t.isFunction(s) && s.call(this, !1), this;
        }
        var e = this.list().position()[this.lt];
        this.rtl ? (e += this.tail) : (e -= this.tail), (this.inTail = !0);
        var r = {};
        return (
          (r[this.lt] = e + 'px'),
          this._update({
            target: this._target.next(),
            fullyvisible: this._fullyvisible.slice(1).add(this._visible.last()),
          }),
          this._animate(r, i, s),
          this
        );
      },
      _animate: function (i, s, e) {
        if (((e = e || t.noop), !1 === this._trigger('animate'))) {
          return e.call(this, !1), this;
        }
        this.animating = !0;
        var r = this.options('animation'),
          n = t.proxy(function () {
            this.animating = !1;
            var t = this.list().find('[data-jcarousel-clone]');
            t.size() > 0 && (t.remove(), this._reload()),
              this._trigger('animateend'),
              e.call(this, !0);
          }, this),
          o = 'object' == typeof r ? t.extend({}, r) : { duration: r },
          a = o.complete || t.noop;
        return (
          s === !1
            ? (o.duration = 0)
            : t.fx.speeds[o.duration] !== void 0 && (o.duration = t.fx.speeds[o.duration]),
          (o.complete = function () {
            n(), a.call(this);
          }),
          this.move(i, o),
          this
        );
      },
      _prepare: function (i) {
        var e,
          r,
          n,
          o = this.index(i),
          a = o,
          l = this.dimension(i),
          h = this.clipping(),
          u = this.vertical ? 'bottom' : this.rtl ? 'left' : 'right',
          c = this.options('center'),
          f = { target: i, first: i, last: i, visible: i, fullyvisible: h >= l ? i : t() };
        if ((c && ((l /= 2), (h /= 2)), h > l)) {
          for (;;) {
            if (((e = this.items().eq((a += 1))), 0 === e.size())) {
              if (!this.circular) {
                break;
              }
              if (
                ((e = this.items().eq(0)), (r = this._visible.index(e) >= 0), i.get(0) === e.get(0))
              ) {
                break;
              }
              if (
                (r && e.after(e.clone(!0).attr('data-jcarousel-clone', !0)),
                this.list().append(e),
                !r)
              ) {
                var d = {};
                (d[this.lt] = this.dimension(e) * (this.rtl ? -1 : 1)), this.moveBy(d);
              }
              this._items = null;
            }
            if (
              ((l += this.dimension(e)),
              (f.last = e),
              (f.visible = f.visible.add(e)),
              (n = s(e.css('margin-' + u))),
              h >= l - n && (f.fullyvisible = f.fullyvisible.add(e)),
              l >= h)
            ) {
              break;
            }
          }
        }
        if (!this.circular && !c && h > l) {
          for (a = o; ; ) {
            if (0 > --a) {
              break;
            }
            if (((e = this.items().eq(a)), 0 === e.size())) {
              break;
            }
            if (
              ((l += this.dimension(e)),
              (f.first = e),
              (f.visible = f.visible.add(e)),
              (n = s(e.css('margin-' + u))),
              h >= l - n && (f.fullyvisible = f.fullyvisible.add(e)),
              l >= h)
            ) {
              break;
            }
          }
        }
        return (
          this._update(f),
          (this.tail = 0),
          c ||
            'circular' === this.options('wrap') ||
            'custom' === this.options('wrap') ||
            this.index(f.last) !== this.items().size() - 1 ||
            ((l -= s(f.last.css('margin-' + u))), l > h && (this.tail = l - h)),
          this
        );
      },
      _position: function (t) {
        var i = this._first,
          s = i.position()[this.lt],
          e = this.options('center'),
          r = e ? this.clipping() / 2 - this.dimension(i) / 2 : 0;
        return (
          this.rtl && !this.vertical
            ? ((s -= this.clipping() - this.dimension(i)), (s += r))
            : (s -= r),
          !e && (this.index(t) > this.index(i) || this.inTail) && this.tail
            ? ((s = this.rtl ? s - this.tail : s + this.tail), (this.inTail = !0))
            : (this.inTail = !1),
          -s
        );
      },
      _update: function (i) {
        var s,
          e = this,
          r = {
            target: this._target || t(),
            first: this._first || t(),
            last: this._last || t(),
            visible: this._visible || t(),
            fullyvisible: this._fullyvisible || t(),
          },
          n = this.index(i.first || r.first) < this.index(r.first),
          o = function (s) {
            var o = [],
              a = [];
            i[s].each(function () {
              0 > r[s].index(this) && o.push(this);
            }),
              r[s].each(function () {
                0 > i[s].index(this) && a.push(this);
              }),
              n ? (o = o.reverse()) : (a = a.reverse()),
              e._trigger('item' + s + 'in', t(o)),
              e._trigger('item' + s + 'out', t(a)),
              (e['_' + s] = i[s]);
          };
        for (s in i) {
          o(s);
        }
        return this;
      },
    });
  })(jQuery, window),
  (function (t) {
    'use strict';
    t.jcarousel.fn.scrollIntoView = function (i, s, e) {
      var r,
        n = t.jCarousel.parseTarget(i),
        o = this.index(this._fullyvisible.first()),
        a = this.index(this._fullyvisible.last());
      if (
        ((r = n.relative
          ? 0 > n.target
            ? Math.max(0, o + n.target)
            : a + n.target
          : 'object' != typeof n.target
            ? n.target
            : this.index(n.target)),
        o > r)
      ) {
        return this.scroll(r, s, e);
      }
      if (r >= o && a >= r) {
        return t.isFunction(e) && e.call(this, !1), this;
      }
      for (
        var l,
          h = this.items(),
          u = this.clipping(),
          c = this.vertical ? 'bottom' : this.rtl ? 'left' : 'right',
          f = 0;
        ;

      ) {
        if (((l = h.eq(r)), 0 === l.size())) {
          break;
        }
        if (((f += this.dimension(l)), f >= u)) {
          var d = parseFloat(l.css('margin-' + c)) || 0;
          f - d !== u && r++;
          break;
        }
        if (0 >= r) {
          break;
        }
        r -= 1;
      }
      return this.scroll(r, s, e);
    };
  })(jQuery),
  (function (t) {
    'use strict';
    t.jCarousel.plugin('jcarouselControl', {
      _options: { target: '+=1', event: 'click', method: 'scroll' },
      _active: null,
      _init: function () {
        (this.onDestroy = t.proxy(function () {
          this._destroy(), this.carousel().one('createend.jcarousel', t.proxy(this._create, this));
        }, this)),
          (this.onReload = t.proxy(this._reload, this)),
          (this.onEvent = t.proxy(function (i) {
            i.preventDefault();
            var s = this.options('method');
            t.isFunction(s)
              ? s.call(this)
              : this.carousel().jcarousel(this.options('method'), this.options('target'));
          }, this));
      },
      _create: function () {
        this.carousel()
          .one('destroy.jcarousel', this.onDestroy)
          .on('reloadend.jcarousel scrollend.jcarousel', this.onReload),
          this._element.on(this.options('event') + '.jcarouselcontrol', this.onEvent),
          this._reload();
      },
      _destroy: function () {
        this._element.off('.jcarouselcontrol', this.onEvent),
          this.carousel()
            .off('destroy.jcarousel', this.onDestroy)
            .off('reloadend.jcarousel scrollend.jcarousel', this.onReload);
      },
      _reload: function () {
        var i,
          s = t.jCarousel.parseTarget(this.options('target')),
          e = this.carousel();
        if (s.relative) {
          i = e.jcarousel(s.target > 0 ? 'hasNext' : 'hasPrev');
        } else {
          var r = 'object' != typeof s.target ? e.jcarousel('items').eq(s.target) : s.target;
          i = e.jcarousel('target').index(r) >= 0;
        }
        return (
          this._active !== i && (this._trigger(i ? 'active' : 'inactive'), (this._active = i)), this
        );
      },
    });
  })(jQuery),
  (function (t) {
    'use strict';
    t.jCarousel.plugin('jcarouselPagination', {
      _options: {
        perPage: null,
        item: function (t) {
          return '<a href="#' + t + '">' + t + '</a>';
        },
        event: 'click',
        method: 'scroll',
      },
      _pages: {},
      _items: {},
      _currentPage: null,
      _init: function () {
        (this.onDestroy = t.proxy(function () {
          this._destroy(), this.carousel().one('createend.jcarousel', t.proxy(this._create, this));
        }, this)),
          (this.onReload = t.proxy(this._reload, this)),
          (this.onScroll = t.proxy(this._update, this));
      },
      _create: function () {
        this.carousel()
          .one('destroy.jcarousel', this.onDestroy)
          .on('reloadend.jcarousel', this.onReload)
          .on('scrollend.jcarousel', this.onScroll),
          this._reload();
      },
      _destroy: function () {
        this._clear(),
          this.carousel()
            .off('destroy.jcarousel', this.onDestroy)
            .off('reloadend.jcarousel', this.onReload)
            .off('scrollend.jcarousel', this.onScroll);
      },
      _reload: function () {
        var i = this.options('perPage');
        if (
          ((this._pages = {}), (this._items = {}), t.isFunction(i) && (i = i.call(this)), null == i)
        ) {
          this._pages = this._calculatePages();
        } else {
          for (
            var s, e = parseInt(i, 10) || 0, r = this.carousel().jcarousel('items'), n = 1, o = 0;
            ;

          ) {
            if (((s = r.eq(o++)), 0 === s.size())) {
              break;
            }
            (this._pages[n] = this._pages[n] ? this._pages[n].add(s) : s), 0 === o % e && n++;
          }
        }
        this._clear();
        var a = this,
          l = this.carousel().data('jcarousel'),
          h = this._element,
          u = this.options('item');
        t.each(this._pages, function (i, s) {
          var e = (a._items[i] = t(u.call(a, i, s)));
          e.on(
            a.options('event') + '.jcarouselpagination',
            t.proxy(function () {
              var t = s.eq(0);
              if (l.circular) {
                var e = l.index(l.target()),
                  r = l.index(t);
                parseFloat(i) > parseFloat(a._currentPage)
                  ? e > r && (t = '+=' + (l.items().size() - e + r))
                  : r > e && (t = '-=' + (e + (l.items().size() - r)));
              }
              l[this.options('method')](t);
            }, a)
          ),
            h.append(e);
        }),
          this._update();
      },
      _update: function () {
        var i,
          s = this.carousel().jcarousel('target');
        t.each(this._pages, function (t, e) {
          return (
            e.each(function () {
              return s.is(this) ? ((i = t), !1) : void 0;
            }),
            i ? !1 : void 0
          );
        }),
          this._currentPage !== i &&
            (this._trigger('inactive', this._items[this._currentPage]),
            this._trigger('active', this._items[i])),
          (this._currentPage = i);
      },
      items: function () {
        return this._items;
      },
      _clear: function () {
        this._element.empty(), (this._currentPage = null);
      },
      _calculatePages: function () {
        for (
          var t,
            i = this.carousel().data('jcarousel'),
            s = i.items(),
            e = i.clipping(),
            r = 0,
            n = 0,
            o = 1,
            a = {};
          ;

        ) {
          if (((t = s.eq(n++)), 0 === t.size())) {
            break;
          }
          (a[o] = a[o] ? a[o].add(t) : t), (r += i.dimension(t)), r >= e && (o++, (r = 0));
        }
        return a;
      },
    });
  })(jQuery),
  (function (t) {
    'use strict';
    t.jCarousel.plugin('jcarouselAutoscroll', {
      _options: { target: '+=1', interval: 3e3, autostart: !0 },
      _timer: null,
      _init: function () {
        (this.onDestroy = t.proxy(function () {
          this._destroy(), this.carousel().one('createend.jcarousel', t.proxy(this._create, this));
        }, this)),
          (this.onAnimateEnd = t.proxy(this.start, this));
      },
      _create: function () {
        this.carousel().one('destroy.jcarousel', this.onDestroy),
          this.options('autostart') && this.start();
      },
      _destroy: function () {
        this.stop(), this.carousel().off('destroy.jcarousel', this.onDestroy);
      },
      start: function () {
        return (
          this.stop(),
          this.carousel().one('animateend.jcarousel', this.onAnimateEnd),
          (this._timer = setTimeout(
            t.proxy(function () {
              this.carousel().jcarousel('scroll', this.options('target'));
            }, this),
            this.options('interval')
          )),
          this
        );
      },
      stop: function () {
        return (
          this._timer && (this._timer = clearTimeout(this._timer)),
          this.carousel().off('animateend.jcarousel', this.onAnimateEnd),
          this
        );
      },
    });
  })(jQuery);
$(document).ready(function () {
  var gaElementImpress = document.querySelector('.ga-impress');
  var gaElementVisibleImpress = document.querySelector('.ga-visible-impress');
  var gaElementImpressLink = document.querySelectorAll(
    '.ga-impress a:not(.facebook):not(.twitter)'
  );
  var gaElementVisibleImpressLink = document.querySelectorAll(
    '.ga-visible-impress a:not(.facebook):not(.twitter)'
  );
  function elementClick(eCategory, elements) {
    for (var i = 0; i < elements.length; i += 1) {
      var itemToClick = elements[i];
      var dataLayerProps = {
        event: 'ga.event',
        eCategory: eCategory,
        eAction: 'Click',
        eLabel: 'Click',
      };
      if (eCategory === 'article_box') {
        if (gaElementVisibleImpress.querySelector('article').hasAttribute('data-test-name')) {
          dataLayerProps['AB'] = gaElementVisibleImpress
            .querySelector('article')
            .getAttribute('data-test-name');
        } else {
          dataLayerProps['AB'] = 'N/A';
        }
        if (gaElementVisibleImpress.querySelector('article').hasAttribute('data-rec-variant')) {
          dataLayerProps['recVar'] = gaElementVisibleImpress
            .querySelector('article')
            .getAttribute('data-rec-variant');
        }
      }
      itemToClick.onclick = function () {
        dataLayer.push(dataLayerProps);
      };
    }
  }
  if (gaElementImpress) {
    var dataLayerProps = {
      event: 'ga.event',
      eCategory: 'Top_article',
      eAction: 'Impress',
      eLabel: 'Impress',
    };
    dataLayer.push(dataLayerProps);
    elementClick('Top_article', gaElementImpressLink);
  }
  if (gaElementVisibleImpress) {
    var dataLayerProps = {
      event: 'ga.event',
      eCategory: 'article_box',
      eAction: 'Impress',
      eLabel: 'Impress',
    };
    if (gaElementVisibleImpress.querySelector('article').hasAttribute('data-test-name')) {
      dataLayerProps['AB'] = gaElementVisibleImpress
        .querySelector('article')
        .getAttribute('data-test-name');
    } else {
      dataLayerProps['AB'] = 'N/A';
    }
    if (gaElementVisibleImpress.querySelector('article').hasAttribute('data-rec-variant')) {
      dataLayerProps['recVar'] = gaElementVisibleImpress
        .querySelector('article')
        .getAttribute('data-rec-variant');
    }
    dataLayer.push(dataLayerProps);
    elementClick('article_box', gaElementVisibleImpressLink);
  }
  if (gaElementVisibleImpress) {
    var options = { rootMargin: '0px 0px -50% 0px' };
    var onIntersection = function onIntersection(entries) {
      for (
        var _iterator = entries,
          _isArray = Array.isArray(_iterator),
          _i = 0,
          _iterator = _isArray ? _iterator : _iterator[Symbol.iterator]();
        ;

      ) {
        var _ref;
        if (_isArray) {
          if (_i >= _iterator.length) {
            break;
          }
          _ref = _iterator[_i++];
        } else {
          _i = _iterator.next();
          if (_i.done) {
            break;
          }
          _ref = _i.value;
        }
        var entry = _ref;
        if (entry.isIntersecting) {
          var dataLayerProps = {
            event: 'ga.event',
            eCategory: 'article_box',
            eAction: 'Visible_impress',
            eLabel: 'Visible_impress',
          };
          if (gaElementVisibleImpress.querySelector('article').hasAttribute('data-test-name')) {
            dataLayerProps['AB'] = gaElementVisibleImpress
              .querySelector('article')
              .getAttribute('data-test-name');
          } else {
            dataLayerProps['AB'] = 'N/A';
          }
          if (gaElementVisibleImpress.querySelector('article').hasAttribute('data-rec-variant')) {
            dataLayerProps['recVar'] = gaElementVisibleImpress
              .querySelector('article')
              .getAttribute('data-rec-variant');
          }
          dataLayer.push(dataLayerProps);
          io.unobserve(entry.target);
        }
      }
    };
    var io = new IntersectionObserver(onIntersection, options);
    io.POLL_INTERVAL = 100;
    io.observe(gaElementVisibleImpress);
  }
});
